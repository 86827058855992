import type {
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
} from 'react';
import clsx from 'clsx';

export type DetailProps<C extends ElementType = 'span'> = {
  className?: string;
  as?: C;
} & ComponentPropsWithoutRef<C> &
  PropsWithChildren;

export function Detail<C extends ElementType = 'span'>({
  as,
  className,
  ...props
}: DetailProps<C>) {
  const As = as ?? 'span';
  const classes = clsx(
    'font-poppins text-detail-sm sm:text-detail font-normal',
    className,
  );
  return <As className={classes} {...props} />;
}
